// ************************
// *** COLOUR VARIABLES ***
// ************************
$roy-white: #fff;

$roy-dark-green: #627c14;
$roy-green: #739218;

$roy-blue: #00ccff;
$roy-blue-gradient: #29D8FF; // for adding to gradient with roy-blue
$roy-dark-blue: #00A8CE;
$roy-blue-links: #0000ff;
$twitter-blue: #1da1f3;

$roy-red: #ff0000;
$roy-dark-red: #e60000;
$pinterest-red: #bd081c;

$roy-black-font: #000000;
$roy-charcoal-font: #333333;

$roy-grey: #999999;
$roy-light-grey: #ccc;

// ******************
// *** ICON SIZES ***
// ******************

$icon-small: 1em;
$icon-medium: 2em;
$icon-large: 2.2em;

$roy-font-xs: 0.9em;
$roy-font-small: 1em;
$roy-font-medium: 1.6em;
$roy-font-large: 2em;

// ********************************
// *** MIXINS FOR MEDIA QUERIES ***
// ********************************

// less than 479, i.e. small phone screen
@mixin phone {
  @media only screen and (max-width: #{$screen-xs - 1px}) {
    @content;
  }
}

// between 768 pixels and 991 pixels, i.e. small tablet
@mixin smallTablet {
  @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

// between 992 and 1199 pixels, i.e. tablet and medium screens
@mixin tablet {
  @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

// large screens only
@mixin largeScreen {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// above recipe comes from https://davidwalsh.name/write-media-queries-sass


// ***************************
// *** PADDING and BORDERS ***
// ***************************

$micro-gutter: 5px;
$small-gutter: 10px;
$medium-gutter: 15px;
$large-gutter: 25px;

$roy-border-thin: 1px;
$roy-border-medium: 4px;
$roy-border-thick: 8px;
$roy-border-style: solid;
$roy-border-style-games: dotted;

// ***********************
// *** UTILITY CLASSES ***
// ***********************

.padding-top-lg {
  padding-top: $large-gutter;
}
.padding-bottom-lg {
  padding-bottom: $large-gutter;
}

.padding-top-md {
  padding-top: $medium-gutter;
}
.padding-bottom-md {
  padding-bottom: $medium-gutter;
}

.margin-top-lg {
  margin-top: $large-gutter;
}
.margin-bottom-lg {
  margin-bottom: $large-gutter;
}

.margin-bottom-sm {
  margin-bottom: $small-gutter;
}

.margin-top-md {
  margin-top: $medium-gutter;
}
.margin-bottom-md {
  margin-bottom: $medium-gutter;
}

// *****************************
// *** CONTAINER BACKGROUNDS ***
// *****************************

.container-header {
  background-color: $roy-white;
  padding-top: $small-gutter;
}

.container-main {
  background-color: $roy-white;
}

// ***************
// *** BUTTONS ***
// ***************

// roy red buttons
// use the button-variant mixin
.btn-roy-red-mini {
  @include button-variant($roy-white, $roy-red, $roy-dark-red);

  @include phone {
    @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
  }
}

.btn-roy-green-mini {
  @include button-variant($roy-white, $roy-green, $roy-dark-green);

  @include phone {
    @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
  }
}

.btn-roy-white {
  @include button-variant($roy-charcoal-font, $roy-white, $roy-dark-red);
}

.btn-roy-red {
  @include button-variant($roy-white, $roy-red, $roy-dark-red);
}

.btn-roy-green {
  @include button-variant($roy-white, $roy-green, $roy-dark-green);
}

.btn-roy-red-rect-100 {
  width: 100%;
  @include button-variant($roy-white, $roy-red, $roy-dark-red);
  @include button-size($padding-small-vertical, $padding-small-horizontal, $roy-font-medium, $line-height-small, 0);
}

.btn-roy-green-rect-100 {
  width: 100%;
  @include button-variant($roy-white, $roy-green, $roy-dark-green);
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-base, $line-height-small, 0);
}

.btn-roy-mobile-100-green {
  @include button-variant($roy-white, $roy-green, $roy-dark-green);

  @include phone {
    display: block;
    width: 100%;
  }
}

.btn-group-quick-menu {
  position: relative;
  display:block;
  vertical-align: middle;
  margin-bottom: $medium-gutter;
}

.btn-icon-top-banner {
  padding: 0;
  border: none;
}

.btn-roy-green-filter {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 8px 2px;
  border: 1px solid $roy-green;
  background: $roy-green;
  border-radius: 50rem;
  text-decoration: none;
  color: $roy-white;
  transition: background 0.4s, border 0.4s;
  &:hover {
    background: $roy-dark-green;
    border: 1px solid $roy-dark-green;
    text-decoration: none;
    color: $roy-white;
  }
}

// ***************************
// *** TABS BEHIND HEADERS ***
// ***************************

@mixin rtzTabBackground ($tabColor, $tabRadius, $fontColor) {
  background-color: $tabColor;
  padding: $micro-gutter $small-gutter;
  border-radius: $tabRadius; // top left, top right, bottom right, bottom left
  color: $fontColor;
}

@mixin tabBottomBorder ($thickness, $rtzStyle, $rtzColor) {
  border-bottom: $thickness $rtzStyle $rtzColor;
  padding-bottom: 2px;
  padding-top: 4px;
}

@mixin rtzTab ($tabColor, $tabCorner) {
  @include tabBottomBorder(2px, $roy-border-style, $tabColor);
  span {
    @include rtzTabBackground($tabColor, $tabCorner, $roy-white);
  }
}

.tab-red {
  @include rtzTab($roy-red, 0px 10px 0px 0px);
}

.tab-grey {
  @include rtzTab($roy-grey, 0px 10px 0px 0px);
}

// ***********************
// *** THUMBNAIL SIZES ***
// ***********************

$thumbnailWidth: 100px;



// ******************
// *** TOP BANNER ***
// ******************


.topBanner {

  line-height: 35px;
  min-height: 60px;
  border-bottom: $roy-border-style $roy-border-medium $roy-grey;
  ul {
    display: -webkit-flex;
    display:flex;
    -webkit-justify-content: center;
    justify-content: center;
  }
  p {
    display: -webkit-flex;
    display:flex;
    -webkit-justify-content: center;
    justify-content: center;
  }

  @include phone {
    line-height: 20px;

  }


  @include smallTablet {
    ul {
      float: right!important;
    }
    p {
      float: left!important;
    }

  }

  @include tablet {
    ul {
      float: right!important;
    }
    p {
      float: left!important;
    }
  }

  @include largeScreen {
    ul {
      float: right!important;
    }
    p {
      float: left!important;
    }
  }

  // hyperlinks
  a {
    text-decoration: none;
  }

  &:hover {

  }

}


// ******************
// *** NAVIGATION ***
// ******************

#navWrapper {
  padding: 0 0 0 0;
  background-color: $roy-white;
  position: relative;
  // height: 100px;
}

.navbar {
  z-index: 9999; // added after problems with menu behind other page objects - might create an issue with modal popups etc.
  margin-bottom: $small-gutter;
}

.navbar-brand {
  padding: 4px 15px 0px 15px;
}

.navbar-nav {
  background-color: $roy-white;

  @include phone {
    margin: 20px -15px;
    float: left;
    font-size: 1.2em;
  }

  float: right;
  font-size: 1.4em;

  li a {
    padding-bottom: 22px
  }
}

// *** break point for button to appear
@media (max-width: 1000px) {
    .navbar {
      background-color: $roy-white;
    }

    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
        font-size: 1.2em;
    }
    .navbar-nav li {
        float: none;
    }
    .navbar-nav li a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
} // end break point for button to appear

.containerWithLogo {
  position: relative;
  z-index: 1001;
}

.redNavBar {
  height: 19px;
  position: absolute;
  background-color: $roy-red;
  width: 100%;
  top: 82px;
  z-index: 1000;
  content: "";
}


// **************
// *** FOOTER ***
// **************

#footerColumn {
  padding-left: 0px;
  padding-right: 0px;
}

.greyBar {
  content: "";
  width: 100%;
  height: 5px;
  border: $roy-border-style $roy-border-thin $roy-grey;
  background-color: $roy-light-grey;
}

#footerLinksContainer {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  a {
    color: $roy-charcoal-font;
  }
  padding-top: 2px;
  @include smallTablet {
    padding-right: 0px;
  }
  @include tablet {
    padding-right: 0px;
  }
  @include largeScreen {
    padding-right: 0px;
  }
}

.footerNav {
  text-align: center;
  line-height: 30px;
  li {
    display: inline-block;
  }

  a {

  }

  @include smallTablet {
    float: right!important;
  }
  @include tablet {
    float: right!important;
  }
  @include largeScreen {
    float: right!important;
  }
}

// *** MARQUEE CSS CODE

.footerTickerContainer {
  background-color: $roy-white;
}


/* Make it a marquee */
.marquee {
    font-size: $roy-font-xs;
    width: 100%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    background-color: $roy-blue;
    // box-sizing: border-box;
    // border: 1px green solid;
}

.marquee span {
    display: inline-block;
    padding-left: 100%;
    text-indent: 0;
    // border: 1px red solid;
    animation: marquee 15s linear infinite;
}
.marquee span:hover {
    animation-play-state: paused
}

/* Make it move */
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

// ***** END

// ****************
// *** HOMEPAGE ***
// ****************

.homeOverview {
  margin-bottom: $large-gutter;
  h1 {
    font-size: $roy-font-small;
    margin: $medium-gutter 0px;
  }
  ul {
    font-size: $roy-font-xs;
    margin-bottom: $large-gutter;
    list-style-type: none;
    padding-left: $large-gutter;

  }

  li::before {
    font-family: 'rtzfonts';
    content: '\63';
    margin: 0 0.2em 0 -1.2em;
    color: $roy-blue;
  }

  li {
    margin-bottom: $medium-gutter;
  }

  // following span tag was aligning the bullet point vertically in Chrome, but it wasn't working in Safari
  // come back to this if we have time...
  /*li span {
    display:inline-block;
    vertical-align:middle;
    padding-left: $small-gutter;
  }*/

}

// *** HOME CAROUSEL ***
.carousel-caption-home {
  position: absolute;
  left: 5%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
  text-shadow: $carousel-text-shadow;
  & .btn {
    text-shadow: none; // No shadow for button elements in carousel-caption
  }

  @include phone {
    visibility: hidden;
  }
}


.hideImageSmallTablet {
  max-width: 100%;
  width: 100%;
  display: block;
  height: auto;
  @include smallTablet {
    display: none;
  }
}

.showImageSmallTablet {
  max-width: 100%;
  display: none;
  height: auto;
  @include smallTablet {
    display: block;
  }
}


.featureButton {
  position: absolute;
  bottom: $large-gutter;
  right: $large-gutter;
  @include smallTablet {
    display: none;
  }
}

.extraMarginBottom {
  margin-bottom: $small-gutter;
}

// *** set of four home features
.featureFourBlock {
  border-top: $roy-border-style $roy-border-medium $roy-red;
}

.pullFeatureRight {
  @include phone {
    float: right!important;
  }
}

// ***********************************************
// *** STANDARD PAGES, i.e. with righthand col ***
// ***********************************************

.cornerThumbnail {
  @include phone {
    width: 110px;
    height: 110px;
    padding-right: $small-gutter;
    padding-bottom: $small-gutter;
    float: left;
  }

  @include smallTablet {
    width: 140px;
    height: 140px;
    float: left;
    padding-right: $medium-gutter;
    padding-bottom: $medium-gutter;
  }

  @include tablet {
    width: 140px;
    height: 140px;
    float: left;
    padding-right: $medium-gutter;
    padding-bottom: $medium-gutter;
  }

  @include largeScreen {
    width: 170px;
    height: 170px;
    float: left;
    padding-right: $medium-gutter;
    padding-bottom: $medium-gutter;
  }


}

// ************************************
// *** GAME INDEXES & LANDING PAGES ***
// ************************************

.gameOverviewBorders {

  margin-top: $medium-gutter;

  &:after {
    content: "";
    display: block;
    margin: $small-gutter $medium-gutter;
    border-bottom: $roy-border-medium $roy-border-style-games $roy-grey;
  }
}

.contentLandingBlock {

  h2 {
    font-size: $roy-font-medium;
    margin-top: $small-gutter;
  }

  h3 {
    font-size: $roy-font-medium;
  }


  @include phone {
    li {
      display: block;
      padding-bottom: $small-gutter;
    }
  }

  // dotted lines
  &:before {
    content: "";
    display: block;
    margin: $large-gutter $medium-gutter $small-gutter $medium-gutter;
    border-bottom: $roy-border-medium $roy-border-style-games $roy-grey;
  }

  .centreLogo {

    @include phone {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .gameThumbnails {
    width: $thumbnailWidth;
    height: auto;
  }

}

.panelFontColor {
  a {
    color: $roy-white;
  }
}


.extraPaddingBullets {
  li {
    padding-right: $small-gutter;
    padding-bottom: $small-gutter;
  }
}


.outerFeatured {
  width: 100%; 
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px; 
  padding-bottom: 10px;

  .innerFeatured {
    position: relative; 
    width: 100%; 
    // background: $roy-blue;
    background: linear-gradient($roy-blue-gradient 0%, $roy-blue 100%);
    padding: 20px 10px; 
    display: flex; 
    flex-direction: row; 
    border: 1px solid $roy-dark-blue; 
    border-radius: 10px; 
    box-shadow: 5px 5px 15px -4px rgba(0,0,0,0.4);
  }

  @media(max-width: 600px) {
    .innerFeatured {
      flex-direction: column;
    }
  }

  .leftFeatured {
    flex: 0 0 35%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    padding: 10px;
  }

  .rightFeatured {
    flex: 0 0 65%; 
    display: flex; 
    flex-direction: column; 
    padding: 10px;
  }

  .featuredResources {
    color: $roy-blue-links;
    transition: color 0.4s;
    &:hover {
      text-decoration: none;
      color: $roy-white;
    }
  }

  .featuredPill {
    position: absolute; 
    top: -4px; 
    left: -14px; 
    background: red; 
    border-radius: 5px; 
    border: 1px solid brown; 
    padding: 6px 10px; 
    transform: rotate(-20deg);
    p {
      color: white; 
      font-size: 0.8em; 
      margin-bottom: 0px; 
      text-transform: uppercase;
    }
  }
}

// ************************
// *** SONG LOGO CANVAS ***
// ************************

.songCanvas {
  width: 100%;
  height: auto;

}


// ************************
// *** GAME ADVERTISING ***
// ************************

.gameBody {
  background-color: white;
}

.adModal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}

.adInner {
  margin: 10% auto; /* 15% from the top and centered */
}

.adContent {
  background-color: #fefefe;
  // margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  // border: 1px solid $roy-grey;
  // border-radius: 5px;
}

.adHeader {
  // padding: 2px 16px;
  background-color: $roy-red;
  color: $roy-white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-bottom: 10px;
}


.adFooter {
  padding: 20px 16px;
  background-color: $roy-red;
  color: $roy-charcoal-font;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.skipAdBlock {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

}

.skipButton {
  display: none;
  font-size: $roy-font-medium;
  cursor: pointer;
}

.skipMessageText {
  font-size: $roy-font-large;
  color: $roy-white;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 6px;
}

.adFreeMessage {
  font-size: $roy-font-small;
  color: $roy-charcoal-font;
}

.closeAds {
  display: none;
  color: $roy-white;
  float: right;
  font-size: 42px;
  font-weight: bold;
  cursor: pointer;
}

// *** VIDEO ***

.adVideoContainer {
  padding-left: 200px;
  padding-right: 200px;
  @include phone {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include smallTablet {
    padding-left: 30px;
    padding-right: 30px;
  }
  text-align: center;
  margin-top: $medium-gutter;
  
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


// **************
// *** BADGES ***
// **************

.badge-highlight {
  color: $roy-white;
  font-size: 8px;
  background-color: $roy-red;
  padding: 2px;
  position: relative;
  top: -6px;
  margin-left: 4px;
  border: 1px solid $roy-dark-red;
  border-radius: 2px;
  text-transform: uppercase;
}

// ********************
// *** SOCIAL MEDIA ***
// ********************

.social-media-outer {
  display: flex; 
  flex-direction: row; 
  justify-content: space-evenly;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: start;

  }



  .social-media-inner {
    border: 1px solid $roy-blue; 
    border-radius: 10px; 
    margin-top: 20px; 
    margin-bottom: 20px; 
    padding: 12px; 
    flex: 0 0 45%; 
    background: $roy-white;
    box-shadow: 5px 5px 15px -4px rgba(0,0,0,0.4);

    @media(max-width: 650px) {
      margin-top: 10px;
    }

    .btn-link {
      &:hover {
        text-decoration: none;
      }
    }

    img {
      &:hover {
        border-bottom: none;
      }
    }

  }

}

// ************
// *** BLOG ***
// ************

.blog-hero-outer {
  // padding: 20px;
  h1 {
    padding-top: $medium-gutter;
    // padding-bottom: $medium-gutter;
  }

  

  .blog-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .blog-hero-date {
      color: $roy-grey;
      font-size: 1rem;
      text-transform: uppercase;
      margin-bottom: 0px;
      @media(max-width: 991px) {
        margin-bottom: $small-gutter;
      }
    }

    .blog-category-outer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      p {
        color: $roy-grey;
        margin-bottom: 0px;
        font-size: 0.8rem;
      }

      @media(max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;

        p {
          margin-bottom: $small-gutter;
        }

      }

      .blog-category-inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        @media(max-width: 575px) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .blog-category-btn {
          display: inline-block;
          background: $roy-green;
          border: 1px solid $roy-dark-green;
          border-radius: 50rem;
          color: $roy-white;
          font-size: 1rem;
          transition: background 0.4s;
          padding: 8px 12px;
          margin-left: $micro-gutter;
          text-decoration: none;
      
          &:hover {
            background: $roy-dark-green;
          }

          @media(max-width: 575px) {
            margin-bottom: $micro-gutter;
          }
        }

      }// end of blog-category inner

    
      

    } // end of blog-category-outer
  }

  img {
    width: 100%;
    margin-bottom: $medium-gutter;
  }

}

.blog-main-content {

  line-height: 1.8;
  color: $roy-charcoal-font;
  font-size: 1em;

  a {
    text-decoration: none;
    color: $roy-blue-links;
    transition: color 0.4s;
    &:hover {
      color: $roy-blue;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

}

.blog-row {
  display: flex;
  flex-wrap: wrap;
}


.blog-card {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 40px;

  // outer div
  .blog-card-outer {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid $roy-light-grey;
    border-radius: 10px;
    // box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.4);
    box-shadow: 2px 2px 15px 0px rgba(0,0,0,0.3);
    background: $roy-white;
    overflow: hidden;
    position: relative;

    a {

      img {
        max-width: 100%;
        height: auto;
        flex: 0 0 auto;
        transition: transform 0.4s;
      }

      &:hover {

        img {
          transform: scale(1.03);
        }
      }

    }

    .blog-card-categories {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      position: absolute;
      right: 10px;
      top: 10px;
 
      p {
        padding: 8px 16px;
        background: $roy-green;
        box-shadow: 2px 2px 15px 0px rgba(0,0,0,0.3);
        border-radius: 50rem;
        color: $roy-white;
        margin-bottom: 5px;
        font-size: 0.9rem;
        
      }
    }

    .blog-card-body {
      flex: 1 0 auto;
      padding: 20px;

      a:not(.btn) {
        text-decoration: none;
        color: $roy-charcoal-font;

        h2, h3 {
          font-size: 1.8rem;
          margin-top: 0px;
          
          transition: color 0.4s;
          
        }

        &:hover {
          color: $roy-blue-links!important;
          
        }

      }

      .blog-card-date {
        font-size: 1rem;
        color: $roy-grey;
        text-transform: uppercase;
      }
      
     
  
      p {
        line-height: 1.8;
        color: $roy-grey;
      }

    }

    

  }

}



// ****************
// *** RTZFONTS ***
// ****************
@charset "UTF-8";

@font-face {
  font-family: "rtzfonts";
  src:url("../fonts/rtzfonts.eot");
  src:url("../fonts/rtzfonts.eot?#iefix") format("embedded-opentype"),
    url("../fonts/rtzfonts.woff") format("woff"),
    url("../fonts/rtzfonts.ttf") format("truetype"),
    url("../fonts/rtzfonts.svg#rtzfonts") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "rtzfonts" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "rtzfonts" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle; // this is needed to align the icons, also parent line-height is important
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelope-o:before {
  content: "\65";
  color: $roy-charcoal-font;
  font-size: $icon-medium;

}
.icon-twitter-square:before {
  content: "\74";
  color: $twitter-blue;
  font-size: $icon-medium;
}
.icon-pinterest:before {
  content: "\70";
  color: $pinterest-red;
  font-size: $icon-medium;
}
.icon-laptop:before {
  content: "\61";
}
.icon-tablet:before {
  content: "\62";
}
.icon-stop:before {
  content: "\63";
  color: $roy-blue;
}
.icon-apple:before {
  content: "\64";
}
.icon-android:before {
  content: "\66";
}
.icon-chrome:before {
  content: "\67";
}
.icon-internet-explorer:before {
  content: "\68";
}
.icon-firefox:before {
  content: "\69";
}
.icon-safari:before {
  content: "\6a";
}
